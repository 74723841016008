
import React, { Component } from 'react';
import billingService from '../../../services/billingService';
import { Divider, Grid, Typography } from '@mui/material';
import { formatInLakhsCrores, getStatsData } from '../../common/util';
import BackButton from '../../common/backButton';
import MatChip from '../../common/matChip';
import { withTranslation } from 'react-i18next';
import { prepareFeeChartData } from '../../common/chartUtil';
import { Bar } from 'react-chartjs-2';
import BillingAnalysisByProgramTable from './billingAnalysisByProgramTable';

class BillingAnalysisByProgram extends Component {
  state = {}


  async componentDidMount() {
    const { data } = await billingService.getAnalysisByPrograms();
    // console.log("data========:", data);
    this.setState({ data });
    const stats = getStatsData();
    this.setState({ stats })
    const chartData = prepareFeeChartData(data, "code")
    // console.log("chartdata:", chartData);
    this.setState({ chartData })
  }

  getChipItem(label, count, className = "default") {
    return <><span>{label}</span> <br></br> <span className="count-label">{count ? count : 0}</span></>
  }
  render() {
    const { data, stats, chartData } = this.state;
    const { t } = this.props;
    return (<>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component={"h"} variant='h6' >
            {"Billing Analysis by " + t("common:Program")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems='flex-end'>
          <Grid container direction='row-reverse'>
            <BackButton  {...this.props} />
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container>
        <MatChip variant='outlined' color={"info"} label={this.getChipItem("Billing", formatInLakhsCrores(stats?.bcc.Billing))} /> &nbsp;
        <MatChip variant='outlined' color={"success"} label={this.getChipItem("Collection", formatInLakhsCrores(stats?.bcc.Collection))} />&nbsp;
        <MatChip variant='outlined' label={this.getChipItem("Discount", formatInLakhsCrores(stats?.bcc.Discount))} />&nbsp;
        <MatChip variant='outlined' color={"error"} label={this.getChipItem("Collectable", formatInLakhsCrores(stats?.bcc.Collectable))} />&nbsp;
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={7} item>
          {chartData && <Bar style={{width:"100%",height:"100%"}} options={{ indexAxis: 'x',responsive:true }}
            data={chartData.data ? chartData.data : {}} />}
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <BillingAnalysisByProgramTable  data={data} />
        </Grid>

      </Grid>
    </>);
  }
}

export default withTranslation("billing", "common")(BillingAnalysisByProgram);