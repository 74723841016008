import React from 'react';
import MatTable from '../../common/matTable';
import { formatInLakhsCrores } from '../../common/util';
const BillingAnalysisByProgramTable = ({data}) => {
    const columns=[
        {
            label:"Program",
            path:"code"
        },
        {
            label:"Billing",
            path:"billing",
            className:"text-blue",
            content:(item)=>{
                return formatInLakhsCrores(item.billing)
            },
            numeric:true
        },
        {
            label:"Collection",
            path:"collection",
            className:"text-green",
            content:(item)=>{
                return formatInLakhsCrores(item.collection)
            },
            numeric:true
        },
        {
            label:"Discount",
            path:"discount",
            content:(item)=>{
                return formatInLakhsCrores(item.discount)
            },
            numeric:true
        },
        {
            label:"Collectable",
            path:"collectable",
            className:"text-red",
            content:(item)=>{
                return formatInLakhsCrores(item.collectable)
            },
            numeric:true
        }
    ]
    return ( <MatTable heading={"Billing Analysis"} paginate={false} data={data} columns={columns} /> );
}
 
export default BillingAnalysisByProgramTable;